import {
  NavigateOptions,
  useLocation,
  useNavigate as useNavigation,
  useNavigationType as internalUseNavigationType,
} from 'react-router-dom';
import { RoutePath } from 'enums/Routes';
import { UrlParams } from 'enums/UrlParams';

export const useNavigate = () => {
  const navigation = useNavigation();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  params.delete(UrlParams.FBC);
  params.delete(UrlParams.FBP);
  params.delete(UrlParams.AnonymousId);
  params.delete(UrlParams.FullName);
  params.delete(UrlParams.Credentials);
  params.delete(UrlParams.CardColor);
  params.delete(UrlParams.ProfessionGroup);
  params.delete(UrlParams.Event);
  params.delete(UrlParams.Partner);
  params.delete(UrlParams.ResetState);

  // The flow query string will collide with refreshing on changing flows
  // also, we shouldn't rely on the flow query string to determine the flow
  // anywhere in the app after the initial routing
  params.delete(UrlParams.Flow);

  return (to: RoutePath, options?: NavigateOptions) => navigation({ pathname: to, search: params.toString() }, options);
};

export const useNavigationType = internalUseNavigationType;
